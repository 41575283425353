@font-face {
    font-family: "deodrum";
    src: url("../fonts/ttf/diodrum-arabic-extralight.ttf") format("truetype");
    font-weight: 300;
}
@font-face {
    font-family: "deodrum";
    src: url("../fonts/ttf/diodrum-arabic-light.ttf") format("truetype");
    font-weight: 400;
}
@font-face {
    font-family: "deodrum";
    src: url("../fonts/ttf/diodrum-arabic-Regular.ttf") format("truetype");
    font-weight: 500;
}
@font-face {
    font-family: "deodrum";
    src: url("../fonts/ttf/diodrum-arabic-medium.ttf") format("truetype");
    font-weight: 600;
}
@font-face {
    font-family: "deodrum";
    src: url("../fonts/ttf/diodrum-arabic-semibold.ttf") format("truetype");
    font-weight: 700;
}
@font-face {
    font-family: "deodrum";
    src: url("../fonts/ttf/diodrum-arabic-bold.ttf") format("truetype");
    font-weight: 800;
}

// @import '~bootstrap/dist/css/bootstrap.rtl.min.css';
@import url('./../css/kamadatepicker.min.css');

html[dir="rtl"] body{
    font-family: "deodrum", system-ui,-apple-system,"Segoe UI",Roboto,"Helvetica Neue",Arial,"Noto Sans","Liberation Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
    font-weight: 600;
    font-size: 15px;
}
html[dir="rtl"] body h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1{
    font-weight: 600;
}
html[dir="rtl"] body .bd-main{
    font-size: 14px;
}
html[dir="rtl"] body .alert-dismissible .btn-close{
    right: unset;
    left: 0;
    box-shadow: none !important;
    font-size: 13px;
}
html[dir="rtl"] body .form-switch .form-check-input{
    margin-left: 0;
}
html[dir="rtl"] body .form-group .show-password{
    left: 10px;
    right: unset;
}
html[dir="rtl"] body .select2-container--default .select2-selection--single .select2-selection__clear{
    margin-left: 20px;
    margin-top: 5px;
}
html[dir="rtl"] body .toast-container{
    top: 0;
    right: 0;
    left: unset;
}
html[dir="rtl"] body .modal-header .btn-close{
    margin: calc(-0.5 * var(--bs-modal-header-padding-y)) auto 
        calc(-0.5 * var(--bs-modal-header-padding-y)) calc(-0.5 * var(--bs-modal-header-padding-x));
}